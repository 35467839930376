import React from 'react'

// https://codedaily.io/tutorials/6/Using-Functions-as-Children-and-Render-Props-in-React-Components

class JsonDataLoader extends React.Component {
  state = {
    loading: true,
    error: false,
    data: null,
  };

  componentDidMount() {
    this.request();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.url !== this.props.url) {
      this.request();
    }
  }

  request() {
    let data = window.sessionStorage.getItem(this.props.url);
    if (this.props.use_cache && data) {
      this.setState({ data: JSON.parse(data), loading: false});
    }
    else {
      fetch(this.props.url, this.props.options)
      // we should check status code here and throw for errors so our catch will work.
      .then(res => res.json())
      .then((data) => {
        window.sessionStorage.setItem(this.props.url, JSON.stringify(data));
        this.setState({ data: data, loading: false });
      })
      .catch((err) => this.setState({ loading: false, error: true }))
    }
  }

  render() {
    return (
      <>
        {this.props.children({
          ...this.props,
          ...this.state,
        })}
      </>
    );
  }
}


export {
  JsonDataLoader
};