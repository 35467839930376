import React from 'react'

import Container from 'react-bootstrap/Container'
import AsyncSelect from 'react-select/async';
import { JsonDataLoader } from './json_data_loader';

import 'bootstrap/dist/css/bootstrap.min.css'


import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';


class App extends React.Component {
  render() {
    return <Main />
  }
}

function loadOptions(inputValue, callback) {
  console.log('/api/v1/searchhint?keyword='+inputValue);
  fetch('/api/v1/searchhint?keyword='+inputValue)
    .then(resp => resp.json())
    .then((resp) => {
      callback(resp.suggestions.map((rec, index) => ({value: rec.search, label: rec.search, record: rec})));
    })
    .catch((error) => {
      console.log(error);
    });
}

class Main extends React.Component {
  state = { selected: null }

  handleSelect = (e) => {
    console.log('selected ', e);
    this.setState({ selected: Object.assign({}, e)} );
  }

  render() {
    const selected = this.state.selected;
    console.log('render', selected);

    return (
      <>
        <Container>
          <div style={{width: '360px', marginTop: '20px'}}>
          <AsyncSelect 
              value={selected}
              cacheOptions
              loadOptions={loadOptions}
              onChange={this.handleSelect}
              //onInputChange={this.handleInputChange}
            />
          </div>
        </Container>
        <hr/>
        { (selected && selected.record) ? 
          (<JsonDataLoader url={"/api/v1/" + selected.record.property_type + '/' + selected.record.key + '/tx_sale' +
                                (selected.record.type === 'BUILDING' ? '?block='+selected.label.split(' ')[0] : '')}>
            {({loading, error, data}) => {
              console.log(loading, error, data);
              if (loading) 
                return <p> Loading </p>;
              else if (error)
                return <p> error: {error} </p>;

              return (
                <BootstrapTable classes="transaction_table"
                  keyField='id'
                  data={ data.results }
                  columns={ columns }
                  hover
                  bootstrap4
                  striped
                  />
              );
            }}
          </JsonDataLoader>)
          : <div/>
        }
      </>
    );
    
  }
}

function price_formatter(cell, row, rowIndex, formatExtraData) {
  return cell.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function unit_price_psf_formatter(cell, row, rowIndex, formatExtraData) {
  return Math.floor(row.price / row.floor_area);
}

function floor_area_formatter(cell, row, rowIndex, formatExtraData) {
  return Math.floor(row.floor_area).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
}

function unit_formatter(cell, row, rowIndex, formatExtraData) {
  if (row.floor === 'G')
    return 'Ground';
  return '#'+row.floor+'-'+row.unit;
}

const columns = [
  /*{
    dataField: 'project',
    text: 'Project',
  }, */{
    dataField: 'blk',
    text: 'Block',
    sort: true,
  }, {
    dataField: 'postal',
    text: 'Postal',
  }, {
    dataField: 'unit',
    text: 'Unit',
    sort: true,
    formatter: unit_formatter
  }, {
    dataField: 'floor_area',
    text: 'FloorArea  (sqft)',
    formatter: floor_area_formatter
  }, {
    dataField: 'contract_date',
    text: "SaleDate",
    sort: true
  }, {
    dataField: 'price',
    text: 'Price',
    formatter: price_formatter
  }, {
    dataField: 'unit_price',
    text: '$ PSF',
    formatter: unit_price_psf_formatter
  }
];


export default App;
